/** @jsx jsx */
import { jsx, Spinner } from "theme-ui";
import { useMemo, useEffect } from "react";

import { useQueryParam, StringParam } from "use-query-params";

import { useLoginWithSbt } from "gatsby-plugin-hfn-profile/components/Login";
import { navigate } from "gatsby";
import {
  useGlobals,
  useGlobalsBoolean,
} from "gatsby-plugin-hfn-profile/globals";

const colorKeys = [
  "background",
  "text",
  "primary",
  "secondary",
  "errortext",
  "errorbg",
  "muted",
  "highlight",
  "background2",
];

const decodeColors = (colorString) => {
  const ret = {};
  colorString
    .split(":")
    .filter((x, xidx) => xidx < colorKeys.length)
    .forEach((x, xidx) => {
      ret[colorKeys[xidx]] = `#${x}`;
    });
  return ret;
};

const Embedded = () => {
  const [c] = useQueryParam("c", StringParam);
  const [sbt] = useQueryParam("sbt", StringParam);
  const [p] = useQueryParam("p", StringParam);

  const { loading } = useLoginWithSbt(sbt);
  // const loading = !sbt; // bypass for now

  const colors = useMemo(
    () =>
      decodeColors(
        c || `ffffff:000000:228b22:006400:ba0000:fed4d4:f6f6f6:efeffe:f0f0f0`
      ),
    [c]
  );
  const [, setColors] = useGlobals("theme-colors");
  const [, { set: setEmbedded }] = useGlobalsBoolean("embedded");

  const page = p || "/";

  useEffect(() => {
    setColors(colors);
    setEmbedded(true);
  }, [setColors, colors, setEmbedded]);

  useEffect(() => {
    if (!loading) {
      navigate(page);
    }
  }, [loading, page]);

  if (loading) {
    return (
      <div sx={{ height: "100%" }}>
        <Spinner />
      </div>
    );
  }
  return <div />;
};

export default Embedded;
